.meet-follow {
  height: 432px;
}
.meet-us {
  background-color: #f3f3f3;
  padding-top: 77px;
  padding-left: 51px;
  padding-bottom: 25px;
}
.follow-us {
  background-color: #dcdcdc;
  padding-top: 77px;
  padding-left: 51px;
  padding-bottom: 25px;
}
.head {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  padding-bottom: 28px;
}
.data-div {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 21px;
  cursor: pointer;
}
.icons {
  color: #ff6161;
  width: 25px;
  height: 25px;
}
.link-container a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.footer-div {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 26px;
}
.footer-span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
}
@media(min-width:992px) {
  .follow-us ,.meet-us{
    padding-top: 203px;
  }
}