.about-div {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  position: relative;
  margin: 37px 10px;
  display: inline-block;
  width: 100%;
 
}
.about-div::after {
   width: 100%;
  position: absolute;
  left: -8px;
  bottom: -28px;
  
  content: url(./../../assets/heading-line.svg);
}
.about-cont {
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 400;
  padding-bottom: 31px;
}
@media (min-width: 992px) {
  .about-div {
    font-size: 26px;
    margin: 57px 10px;
  }
  .about-cont {
    font-size: 16px;
    padding-bottom: 51px;
  }
}
