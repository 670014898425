/* service */

.main-container {
  display: flex;
  align-items: center;
}
.service-container {
  position: relative;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  margin: 37px 10px;
  display: inline-block;
  
}
.service-container::after {
  position: absolute;
  left: 1px;
  content: url(./../../assets/heading-line.svg);
  
}

.service-h {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.paragraph-container p {
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 400;
}

/* banner one */

.banner-container {
  margin-top: 51px;
  background: url("./../../assets/banner-one-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-para-div {
  padding: 30px 55px;
}
.banner-para-div p {
  font-size: 18px;
  font-weight: 400;
  color: #ffff;
}
.banner-link {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  color: #ff6161;
}

/* global */

.global-title {
  margin-top: 51px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
}
.global-second-title {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  margin-top: 12px;
  position: relative;
  margin-bottom: 64px;
  display: inline-block;
}
.global-second-title::after {
  position: absolute;
  content: url(./../../assets/Vector.svg);
  left: 45px;
  bottom: -42px;
  rotate: 1deg;
  transform: scale(.6); 
}


/*banner two */
.bannertwo-img {
  background-image: url(./../../assets//banner-two-second.png);
  height: 298px;
  margin-top: 51px;
  background-repeat: none;
  background-size: cover;
  background-position: center center;
  width: 100%;
  
}
/* support card */


.card-title {
  font-family: "poppins";
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin-top: 51px;
  margin-bottom: 30px;
}

.support-elements {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.support-text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  /* line-height: 1.2; */
  padding-top: 9px;
}
.support-container {
  display: none;
}
/* banner three */

.bg-img-container {
  margin-top: 51px;
  background-image: url(./../../assets/banner-three-bg.png);
  background-size: cover;
  background-repeat: none;
 
}
.banner-three-text {
  padding: 80px 25px;
 
}
.banner-three-text h3 {
  font-size: 20px;
  color: #FFCE4B;
  font-family: "Martel Sans", sans-serif;
  font-weight: 900;
  font-style: normal;
  line-height: 1.7;
  
}
.banner-three-text a {
  color: #f3f3f3;
  padding-top: 11px;
  line-height: 2.5;
  font-family: "poppins";
  font-weight: 500;
  font-weight: 18;
}
.banner-three-bar-img {
  text-align: right;
  margin-bottom: 40px;
  margin-right: 30px;
}

/*media queries

/* services media query */

@media (min-width:992px) {
  
  .service-container {
    font-size: 26px;
    margin: 57px 10px;
  }
  .service-container::after {
    left: 7px;
    bottom: -27px;
  }
  .service-h {
    font-size: 36px;
    margin-bottom: 25px;
  }
  .paragraph-container p{
    font-size: 16px;
  }
  .service-container::after {
    left: 3px;
    bottom: -27px;
  }
  
}

/* banner one media query */
@media (min-width: 992px) {
  .banner-container {
    margin-top: 81px;
  }
  .banner-para-div {
    padding: 50px 80px;
  }
  .banner-para-div p {
    font-size: 24px;
    font-weight: 500;
    color: #ffff;
    margin-right: 550px
  }
  .banner-link {
    font-size: 24px;
    font-weight: 500;
    color: #ff6161;
  }
}

/*global media query */

@media(min-width:992px) {
  .global-title{
    margin-top: 71px;
    font-size: 36px;
  }
  .global-second-title {
    font-size: 42px;
    margin-bottom: 94px;
  }
    .global-second-title::after {
        left: 100px;
        bottom: -44px;
        rotate: 1deg;
        transform: scale(.9); 
      }
}
/*banner two media query */

@media(min-width:992px) {
  .bannertwo-img {
      margin-top: 71px;
      background-image: url(./../../assets/our-principles.png);
      height: 390px;
  }
}
/* support card media query */
@media(min-width:992px) {
    
  .card-title {
      font-size: 42px;
      margin-top: 71px;
      margin-bottom: 51px;
  }
  .support-elements {
      display: flex;
      flex-direction: row;
  }
  .support-container {
      display: inline-block;
      position: relative;
  }
  .support-text {
      font-size: 30px;
      padding-top: 14px;
  }
  .support-container::after {
      position: absolute;
      content: url(./../../assets/line.svg);
      right: 160px;
      bottom: -120px;
  }
}
/*banner three */

@media(min-width:992px) {
  .bg-img-container {
      margin-top: 251px;
  }
.banner-three-text {
  padding: 80px 150px;
 
}
.banner-three-text h3 {
  font-size: 24px;
}

.banner-three-bar-img {
  text-align: right;
  padding-right: 150px;
  margin-top: 99px;
  margin-bottom: 0px;
} 
}


