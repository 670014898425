.navbar {
  background-color: #f2f2f2;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #000000;

  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}

/* .nav-link.active::after {
    content: '';
    border-bottom: 3px solid #0F9CD8;
    display: block; 
    transition: width .3s;
    -webkit-transition: width .3s;
    -moz-transition: width .3s;
    -ms-transition: width .3s;
    -o-transition: width .3s;   
    width: 0;
} */

.nav-item a {
  text-decoration: none;
  color: #000;
  display: flex;
  gap: 10px;
}
.brand-img {
  
}

@media(min-width:992px) {
  .nav-item.active {
    text-decoration: underline;
    text-decoration-color: red;
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 6px;
  }
}