  .contact-div {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    margin: 37px 20px;
    display: inline-block;
  }
  .contact-div::after {
    width: 100%; 
    position: absolute;
    left: -8px;
    bottom: -28px;
    content: url(./../../assets/heading-line.svg);
  }
  .contact-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 96px;
    padding-bottom: 51px;
   
  }
  .contact-p  {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;  
    padding-top: 42px;
  }
  .contact-form-button {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 55px;
  }
  .contact-form {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap: 20px;  
  }
  .contact-form-textfield {
    width: 362px;
    background: #ffffff !important;
    color: #ff6161 !important;
  }
  .contact-form-select  {
    width: 50%;
    background: #ffffff;
    padding-bottom: 2px;
  }
  .contact-form-button {
    width: 362px;
    background-color: #ff6161 !important; 
  }
  .contact-form-select {
    width: 362px;
  }
  @media(min-width:992px){
    .contact-div {
      font-size: 26px;
      margin: 57px 10px;
    }
    .contact-div::after {
      left: 20px;
      bottom: -29px; 
    }
    .contact-img-container {
      margin-top: 136px;
      padding-bottom: 100px;
     
    }
    .contact-p {
      font-size: 26px;
    }
  } 
  .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input{
    padding: 14px !important;
  }